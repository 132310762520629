import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: { authorize: [] }
  },

  // Admins
  {
    path: "/admins",
    name: "Admins",
    component: () => import("@/views/admins/Admins.vue"),
    meta: { authorize: ["SUPERADMIN"] }
  },
  {
    path: "/admins/:id",
    name: "Admin",
    component: () => import("@/views/admins/Admin.vue"),
    meta: { authorize: ["SUPERADMIN"] }
  },

  // Investors
  {
    path: "/inversores/retail",
    name: "InvestorsRetail",
    component: () => import("@/views/investors/RetailInvestors.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/inversores/fund",
    name: "InvestorsFund",
    component: () => import("@/views/investors/FundInvestors.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/inversores/:type/:id",
    name: "Investor",
    component: () => import("@/views/investors/Investor.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/inversores/:type/:id/invertir-multiples-proyectos",
    name: "InvestMultiplesProjects",
    component: () => import("@/views/investors/Invest-multiples-projects.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/inversores/:type/:id/wallet/:wallet_id",
    name: "Wallet",
    component: () => import("@/views/investors/Wallet.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/reembolsos",
    name: "Withdraws",
    component: () => import("@/views/investors/Withdraws.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },

  // Technicians
  {
    path: "/tecnicos",
    name: "Technicians",
    component: () => import("@/views/technicians/Technicians.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/tecnicos/:id",
    name: "Technician",
    component: () => import("@/views/technicians/Technician.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },

  // Borrowers
  {
    path: "/prestatarias",
    name: "Borrowers",
    component: () => import("@/views/borrowers/Borrowers.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/prestatarias/:id",
    name: "Borrower",
    component: () => import("@/views/borrowers/Borrower.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"] }
  },

  // Partners
  {
    path: "/partners",
    name: "Partners",
    component: () => import("@/views/partners/Partners.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/partners/:id",
    name: "Partner",
    component: () => import("@/views/partners/Partner.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },

  // Offices
  {
    path: "/oficinas",
    name: "Offices",
    component: () => import("@/views/offices/Offices.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/oficinas/:id",
    name: "Office",
    component: () => import("@/views/offices/Office.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },

  // Projects
  {
    path: "/proyectos",
    name: "Projects",
    component: () => import("@/views/projects/Projects.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/proyectos/:id",
    name: "Project",
    component: () => import("@/views/projects/Project.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/test-calendar/:id",
    name: "Test-calendar",
    component: () => import("@/views/projects/Test-calendar-payments.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },

  // Project bags
  {
    path: "/bolsas",
    name: "Project-bags",
    component: () => import("@/views/project-bags/Project-bags.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/bolsas/:id",
    name: "Project-bag",
    component: () => import("@/views/project-bags/Project-bag.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "TECHNICIAN"] }
  },

  // Requests
  {
    path: "/solicitudes",
    name: "Requests",
    component: () => import("@/views/requests/Requests.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/solicitudes/:id",
    name: "Request",
    component: () => import("@/views/requests/Request.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "TECHNICIAN", "SUPERTECHNICIAN"] }
  },

  // Locations
  {
    path: "/localizaciones",
    name: "Locations",
    component: () => import("@/views/locations/Locations.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },

  // Financial products
  {
    path: "/productos",
    name: "Products",
    component: () => import("@/views/products/Products.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN", "SUPERTECHNICIAN"] }
  },
  {
    path: "/crear-producto",
    name: "Create-product",
    component: () => import("@/views/products/Create-product.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/editar-producto/:id",
    name: "Edit-product",
    component: () => import("@/views/products/Edit-product.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },

  // Credit transfers
  {
    path: "/crear-envio",
    name: "Create-credit-transfers",
    component: () => import("@/views/credit-transfers/Create-credit-transfers.vue"),
    meta: { authorize: ["SUPERADMIN"] }
  },
  {
    path: "/envios",
    name: "Credit-transfers",
    component: () => import("@/views/credit-transfers/Credit-transfers.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },
  {
    path: "/envio/:id",
    name: "Credit-transfer",
    component: () => import("@/views/credit-transfers/Credit-transfer.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },

  // General settings
  {
    path: "/ajustes",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
    meta: { authorize: ["SUPERADMIN", "ADMIN"] }
  },

  // Profile
  {
    path: "/perfil/:id",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    meta: { authorize: [] }
  },

  // Authentication
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "LayoutLogin"
    }
  },
  {
    path: "/*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, _, next) => {
  const { authorize } = to.meta;
  const isLoggedIn = await store.dispatch("authentication/authCurrentUser");
  const editing = await store.state["editing"];

  if (authorize) {
    const user = await store.getters["user/getUser"];

    // Check is not logged in so redirect to login page with the return url
    if (!isLoggedIn) {
      return next({ path: "/login", query: { returnUrl: to.path } });
    }

    // Check if route is restricted by role and redirect to home page
    if (authorize.length > 0 && !authorize.includes(user?.role)) {
      return next({ path: "/" });
    }

    // Check if any form is editing and cancel navigation
    if (editing) {
      store.commit("SET_SNACKBAR", { show: true, text: "Save or cancel the form changes", color: "warning" });
      return next(false);
    }
  }

  // Check is logged in so block /login page
  if (isLoggedIn && to.path === "/login") {
    return next({ path: "/" });
  }

  next();
});

export default router;
